<template>
  <div v-if="dialogFormVisible2">
    <el-dialog :title="titleTable" :visible.sync="dialogFormVisible" :width="'600px'" @close="close"
      :close-on-click-modal="false">
      <el-form :model="deviceInfoForm" :rules="rules" ref="form" :label-width="'110px'" label-position="right"
        :class="{ 'my-form-readonly': isModelInfo }" size="large">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="marketId" :label="$t('MarkPrice.price.交易市场') + '：'">
              <el-select filterable v-model="deviceInfoForm.marketId" @change="funMarketChange(deviceInfoForm.marketId,true)"
                :placeholder="$t('MarkPrice.price.交易市场Msg')">
                <el-option v-for="item in marketList" :key="item.id" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="productId" :label="$t('MarkPrice.price.资产') + '：'">
              <el-select filterable v-model="deviceInfoForm.productId" :placeholder="$t('MarkPrice.price.资产Msg')">
                <el-option v-for="item in productList" :key="item.id" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="univalent" :label="$t('MarkPrice.price.成本单价') + '：'">
              <el-input v-model="deviceInfoForm.univalent" :placeholder="$t('MarkPrice.price.成本单价Msg')">
                <template slot="append" v-if="appendUnit">
                  <span>{{ appendUnit }}</span>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="quantity" :label="$t('MarkPrice.price.数量') + '：'">
              <el-input v-model="deviceInfoForm.quantity" :placeholder="$t('MarkPrice.price.数量Msg')">
                <template slot="append" v-if="append">
                  <span>{{ append }}</span>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="validYear" :label="$t('MarkPrice.price.有效年份') + '：'">
              <el-date-picker type="year" value-format="yyyy" style="width: 100%" v-model="deviceInfoForm.validYear"
                :placeholder="$t('MarkPrice.price.有效年份Msg')"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="entryDate" :label="$t('MarkPrice.price.入账日期') + '：'">
              <el-date-picker type="date" value-format="yyyy-MM-dd" style="width: 100%"
                v-model="deviceInfoForm.entryDate" :placeholder="$t('MarkPrice.price.入账日期Msg')"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div v-if="isModelInfo">
          <el-button type="primary" :loading="posting" size="small" @click="close()">{{ $t("global.confirm") }}
          </el-button>
        </div>
        <div v-else>
          <el-button @click="close" size="small">{{ $t("global.cancel") }}</el-button>
          <el-button type="primary" :loading="posting" size="small" @click="submitForm('form')">{{
              $t("global.confirm")
          }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api'
import base from '@/templates/add'
export default {
  directives: {},
  filters: {},
  loading: false,
  extends: base,
  props: {},
  data() {
    return {
      titleTable: '',
      dialogFormVisible2: false,
      dialogFormVisible: false,
      posting: false,
      isModelEdit: false,
      isModelAdd: false,
      isModelInfo: false,
      deviceInfoForm: {
        customId: '',
        entryDate: '',
        handleDate: '',
        id: '',
        marketId: '',
        productId: '',
        quantity: '',
        regionId: '',
        sellnum: '',
        sellprice: '',
        typeId: '',
        univalent: '',
        validYear: ''
      },
      rules: {
        marketId: [
          { required: true, message: this.$t('global.请选择'), trigger: ['blur', 'change'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        productId: [
          { required: true, message: this.$t('global.请选择'), trigger: ['blur', 'change'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        validYear: [
          { required: true, message: this.$t('global.请选择'), trigger: ['blur', 'change'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        entryDate: [
          { required: true, message: this.$t('global.请选择'), trigger: ['blur', 'change'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        quantity: [
          { required: true, message: this.$t('global.请输入'), trigger: ['blur'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg'), trigger: ['blur'] },
          { pattern: this.pattern('IntAndZero'), message: this.$t('global.floatMsg'), trigger: ['blur'] },
        ],
        univalent: [
          { required: true, message: this.$t('global.请输入'), trigger: ['blur'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg'), trigger: ['blur'] },
          { pattern: this.pattern('floatAndZero'), message: this.$t('global.floatMsg'), trigger: ['blur'] },
        ],
      },
      marketList: [],
      productList: [],
      regionList: [],
      projectTypeList: [],
    }
  },
  computed: {
    append() {
      let str = ''
      if (this.deviceInfoForm.productId) {
        const obj = this.productList.find(o => o.value === this.deviceInfoForm.productId)
        str =  this.$t(`MarkPrice.index['${obj.unit}']`)
      }
      return str
    },
    appendUnit() {
      let str = ''
      if (this.deviceInfoForm.productId) {
        const obj = this.productList.find(o => o.value === this.deviceInfoForm.productId)
        str = obj.currency
      }
      return str
    }
  },
  created() {
    api.price.queryOptionTypeList({ type: 'marketType' }).then(res => {
      this.marketList = res.data.map(item => ({ ...item, label: item.name, value: item.id }))
    })
    // api.price.queryOptionTypeList({ type: 'marketProductType' }).then(res => {
    //   this.productList = res.data.map(item => ({ ...item, label: item.name, value: item.id }))
    // })
    // api.auth.queryOptionTypeList({ type: 'marketRegionType' }).then(res => {
    //   this.regionList = res.data.map(item => ({ ...item, label: item.name, value: item.id }))
    // })
  },
  methods: {
    async funMarketChange(val, flag=false) {
      if (val) {
        await api.price.queyOptinTypeByMarkid({
          marketId: val,
          types: ['marketProductType']
        }).then(rep => {
          this.productList = rep.data.marketProductType.map(item => ({ ...item, label: item.name, value: item.id }))
          if(flag){
            this.deviceInfoForm.productId = rep.data.marketProductType.length ? rep.data.marketProductType[0].id : ''
          }
        })
      }
    },
    show() {
      this.dialogFormVisible2 = true
      this.$nextTick((_) => {
        this.dialogFormVisible = true
      })
    },
    close() {
      this.isModelEdit = false
      this.isModelAdd = false
      this.isModelInfo = false
      this.dialogFormVisible = false
      this.resetForm()
      this.$nextTick((_) => {
        this.dialogFormVisible2 = false
      })
    },
    add() {
      this.titleTable = this.$t('MarkPrice.price.addTitle')
      this.isModelAdd = true
      this.show()
    },
    edit(data) {
      this.titleTable = this.$t('MarkPrice.price.editTitle')
      this.isModelEdit = true
      this.deviceInfoForm = { ...data }
      this.funMarketChange(this.deviceInfoForm.marketId)
      this.show()
    },
    view(data) {
      this.titleTable = this.$t('MarkPrice.price.detilsTitle')
      this.isModelInfo = true
      this.deviceInfoForm = { ...data }
      this.show()
    },
    // 重制
    resetForm() {
      this.deviceInfoForm = {
        customId: '',
        entryDate: '',
        handleDate: '',
        id: '',
        marketId: '',
        productId: '',
        quantity: '',
        regionId: '',
        sellnum: '',
        sellprice: '',
        typeId: '',
        univalent: '',
        validYear: ''
      }
      this.$refs.form.resetFields()
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.posting = true
          const query = {
            ...this.deviceInfoForm,
            id: this.deviceInfoForm.id,
            updateTime: null
          }
          try {
            await api.price[this.isModelAdd ? 'saveAssetsInfo' : 'updateAssetsInfo'](query)
            this.posting = false
            this.$message.success(this.$t('global.submitOk'))
            this.close()
            this.$parent.pageChange4(this.$parent.table4Query.page)
          } catch (error) {
            this.posting = false
          }
        }
      })
    }
  }
}
</script>
