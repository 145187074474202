<template>
  <div class="g-box">
    <div id="myCanvas-wrap">
      <div class="home-bg"></div>
      <div id="myCanvas">
        <div class="market-main">
          <el-row :gutter="24">
            <el-col :span="12">
              <div class="chart-panel chart-height">
                <el-row type="flex" align="middle" justify="space-between">
                  <el-col :span="10">
                    <div class="chart-tt">
                      <div class="chart-tt-lt"></div>
                      <div class="chart-tt-rt">
                        {{ $t(`MarkPrice.index['${marketObj["0"].name}']`) }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="14" style="text-align: right">
                    <el-select
                      size="small"
                      class="chart-select"
                      v-model="marketObj['0'].productId"
                      clearable
                      @change="setChart1"
                    >
                      <el-option
                        v-for="item in marketObj['0'].productOption"
                        :key="item.value"
                        :value="item.value"
                        :label="item.label"
                      >
                      </el-option>
                    </el-select>
                    <!-- <el-select
                      size="small"
                      v-if="
                        marketObj['0'].option.length !== 1 &&
                        marketObj['0'].option.length !== 0
                      "
                      class="chart-select"
                      v-model="marketObj['0'].regionId"
                      clearable
                      @change="setChart1"
                    >
                      <el-option
                        v-for="item in marketObj['0'].option"
                        :key="item.value"
                        :value="item.value"
                        :label="item.label"
                      ></el-option>
                    </el-select> -->
                  </el-col>
                </el-row>
                <el-empty
                  class="chart"
                  v-show="chart1Empty"
                  :description="$t('MarkPrice.index.暂无数据')"
                ></el-empty>
                <div id="chart1" v-show="!chart1Empty" class="chart">
                  <el-empty
                    :description="$t('MarkPrice.index.暂无数据')"
                  ></el-empty>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="chart-panel chart-height">
                <el-row type="flex" align="middle" justify="space-between">
                  <el-col :span="10">
                    <div class="chart-tt">
                      <div class="chart-tt-lt"></div>
                      <div class="chart-tt-rt">
                        {{ $t(`MarkPrice.index['${marketObj["1"].name}']`) }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="14" style="text-align: right">
                    <el-select
                      size="small"
                      class="chart-select"
                      v-model="marketObj['1'].productId"
                      clearable
                      @change="setChart2"
                    >
                      <el-option
                        v-for="item in marketObj['1'].productOption"
                        :key="item.value"
                        :value="item.value"
                        :label="item.label"
                      >
                      </el-option>
                    </el-select>
                    <!-- <el-select
                      size="small"
                      v-if="
                        marketObj['1'].option.length !== 1 &&
                        marketObj['1'].option.length !== 0
                      "
                      class="chart-select"
                      v-model="marketObj['1'].regionId"
                      clearable
                      @change="setChart2"
                    >
                      <el-option
                        v-for="item in marketObj['1'].option"
                        :key="item.value"
                        :value="item.value"
                        :label="item.label"
                      ></el-option>
                    </el-select> -->
                  </el-col>
                </el-row>
                <el-empty
                  class="chart"
                  v-show="chart2Empty"
                  :description="$t('MarkPrice.index.暂无数据')"
                ></el-empty>
                <div id="chart2" v-show="!chart2Empty" class="chart">
                  <el-empty
                    :description="$t('MarkPrice.index.暂无数据')"
                  ></el-empty>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12">
              <div class="chart-panel chart-height">
                <el-row type="flex" align="middle" justify="space-between">
                  <el-col :span="10">
                    <div class="chart-tt">
                      <div class="chart-tt-lt"></div>
                      <div class="chart-tt-rt">
                        {{ $t(`MarkPrice.index['${marketObj["2"].name}']`) }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="14" style="text-align: right">
                    <el-select
                      size="small"
                      class="chart-select"
                      v-model="marketObj['2'].productId"
                      clearable
                      @change="setChart3"
                    >
                      <el-option
                        v-for="item in marketObj['2'].productOption"
                        :key="item.value"
                        :value="item.value"
                        :label="item.label"
                      >
                      </el-option>
                    </el-select>
                    <!-- <el-select
                      size="small"
                      v-if="
                        marketObj['2'].option.length !== 1 &&
                        marketObj['2'].option.length !== 0
                      "
                      class="chart-select"
                      v-model="marketObj['2'].regionId"
                      clearable
                      @change="setChart3"
                    >
                      <el-option
                        v-for="item in marketObj['2'].option"
                        :key="item.value"
                        :value="item.value"
                        :label="item.label"
                      ></el-option>
                    </el-select> -->
                  </el-col>
                </el-row>
                <el-empty
                  class="chart"
                  v-show="chart3Empty"
                  :description="$t('MarkPrice.index.暂无数据')"
                ></el-empty>
                <div id="chart3" v-show="!chart3Empty" class="chart">
                  <el-empty
                    :description="$t('MarkPrice.index.暂无数据')"
                  ></el-empty>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="chart-panel chart-height">
                <el-row type="flex" align="middle" justify="space-between">
                  <el-col :span="11">
                    <div class="chart-tt">
                      <div class="chart-tt-lt"></div>
                      <div class="chart-tt-rt">
                        {{ $t(`MarkPrice.index['${marketObj["3"].name}']`) }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="14" style="text-align: right">
                    <el-select
                      size="small"
                      class="chart-select"
                      v-model="marketObj['3'].productId"
                      clearable
                      @change="setChart4"
                    >
                      <el-option
                        v-for="item in marketObj['3'].productOption"
                        :key="item.value"
                        :value="item.value"
                        :label="item.label"
                      >
                      </el-option>
                    </el-select>
                    <!-- <el-select
                      size="small"
                      v-if="
                        marketObj['3'].option.length !== 1 &&
                        marketObj['3'].option.length !== 0
                      "
                      class="chart-select"
                      v-model="marketObj['2'].regionId"
                      clearable
                      @change="setChart4"
                    >
                      <el-option
                        v-for="item in marketObj['3'].option"
                        :key="item.value"
                        :value="item.value"
                        :label="item.label"
                      ></el-option>
                    </el-select> -->
                  </el-col>
                </el-row>
                <el-empty
                  class="chart"
                  v-show="chart4Empty"
                  :description="$t('MarkPrice.index.暂无数据')"
                ></el-empty>
                <div id="chart4" v-show="!chart4Empty" class="chart">
                  <el-empty
                    :description="$t('MarkPrice.index.暂无数据')"
                  ></el-empty>
                </div>
              </div>
            </el-col>
            <!-- <el-col :span="12">
              <div class="chart-panel chart-height">
                <el-row type="flex" align="middle" justify="space-between">
                  <el-col :span="11">
                    <div class="chart-tt">
                      <div class="chart-tt-lt"></div>
                      <div class="chart-tt-rt">{{$t(`MarkPrice.index['${marketObj['4'].name}']`) }}</div>
                    </div>
                  </el-col>
                  <el-col :span="5">
                    <el-select class="chart-select" v-model="marketObj['4'].productId" clearable @change="setChart5">
                      <el-option v-for="item in productList" :key="item.value" :value="item.value" :label="item.label">
                      </el-option>
                    </el-select>
                  </el-col>
                </el-row>
                <el-empty class="chart" v-show="chart5Empty" :description="$t('MarkPrice.index.暂无数据')"></el-empty>
                <div id="chart5" v-show="!chart5Empty" class="chart">
                  <el-empty :description="$t('MarkPrice.index.暂无数据')"></el-empty>
                </div>
              </div>
            </el-col> -->
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="chart-panel">
              <el-row>
                  <el-col :span="24">
                  <div class="btn-release" @click="releaseShow">
                      {{ $t("MarkPrice.index.我的发布") }}
                    </div>
                  </el-col>
                  <el-col :span="24">
                    <el-row type="flex" align="bottom" justify="space-between">
                      <div class="chart-tt top">
                        <div class="chart-tt-lt"></div>
                        <div class="chart-tt-rt">
                          {{ $t("MarkPrice.index.碳资产清单") }}
                        </div>
                      </div>
                      <div style="display: flex">
                        <div
                          class="tb-tt price"
                          :class="{ active: table4Query.type === 1 }"
                          @click="setQuery4(1)"
                        >
                          {{ $t("MarkPrice.index.持仓") }}
                        </div>
                        <div
                          class="tb-tt price"
                          :class="{ active: table4Query.type === 2 }"
                          @click="setQuery4(2)"
                        >
                          {{ $t("MarkPrice.index.历史") }}
                        </div>
                      </div>
                    </el-row>
                  </el-col>
                  <el-col :span="24">
                    <el-table
                      :height="296"
                      :data="tableData4"
                      v-loading="table4Loading"
                      stripe
                      border
                      style="width: 100%"
                    >
                      <el-table-column
                        :prop="
                          table4Query.type === 1
                            ? 'productInfo.name'
                            : 'assetsInfo.productInfo.name'
                        "
                        :label="$t('MarkPrice.index.资产名称')"
                        min-width="80"
                        align="center"
                      >
                      </el-table-column>
                      <el-table-column
                        :prop="
                          table4Query.type === 1
                            ? 'marketInfo.name'
                            : 'assetsInfo.marketInfo.name'
                        "
                        :label="$t('MarkPrice.index.交易市场')"
                        min-width="80"
                        align="center"
                      >
                      </el-table-column>
                      <el-table-column
                        :prop="
                          table4Query.type === 1
                            ? 'validYear'
                            : 'assetsInfo.validYear'
                        "
                        :label="$t('MarkPrice.index.有效年份')"
                        min-width="80"
                        align="center"
                      ></el-table-column>
                      <el-table-column
                        :prop="
                          table4Query.type === 1 ? 'entryDate' : 'createTime'
                        "
                        :label="
                          table4Query.type === 1
                            ? $t('MarkPrice.index.入账日期')
                            : $t('MarkPrice.index.处置日期')
                        "
                        min-width="80"
                        align="center"
                      >
                      </el-table-column>
                      <el-table-column
                        :prop="
                          table4Query.type === 1 ? 'quantity' : 'handlenum'
                        "
                        :label="$t('MarkPrice.index.数量')"
                        min-width="80"
                        align="center"
                      >
                        <template slot-scope="scope">
                          <span>{{
                              table4Query.type === 1
                                ? scope.row.quantity
                                : scope.row.handlenum
                            }}
                            {{
                              $t(`MarkPrice.index['${scope.row.unit}']`)
                            }}</span>
                        </template></el-table-column>
                      <el-table-column
                        :prop="
                          table4Query.type === 1 ? 'univalent' : 'handleprice'
                        "
                        :label="
                          table4Query.type === 1
                            ? $t('MarkPrice.index.成本单价')
                            : $t('MarkPrice.index.处置单价')
                        "
                        min-width="80"
                        align="center"
                      >
                      <template slot-scope="scope">
                          <span>{{
                              table4Query.type === 1
                                ? Number(scope.row.univalent).toFixed(2)
                                : Number(scope.row.handleprice).toFixed(2)
                            }}
                            {{ table4Query.type === 1?scope.row.productInfo.currency:scope.row.assetsInfo.productInfo.currency }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="totalcost"
                        :label="$t('MarkPrice.index.总成本')"
                        min-width="80"
                        align="center"
                      >
                      <template slot-scope="scope">
                          <span v-if="scope.row.totalcost">{{ Number(scope.row.totalcost).toFixed(2) }}
                            {{ table4Query.type === 1?scope.row.productInfo.currency:scope.row.assetsInfo.productInfo.currency }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="profit"
                        :label="$t('MarkPrice.index.盈亏')"
                        min-width="80"
                        align="center"
                      >
                      <template slot-scope="scope">
                          <span v-if="scope.row.profit">{{ Number(scope.row.profit).toFixed(2) }}
                            {{ table4Query.type === 1?scope.row.productInfo.currency:scope.row.assetsInfo.productInfo.currency }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="status"
                        :label="$t('MarkPrice.index.状态')"
                        v-if="table4Query.type === 2"
                        min-width="80"
                        align="center"
                      >
                        <template slot-scope="scope">
                          <span>
                            {{ $t(`MarkPrice.index['${scope.row.status}']`) }}
                          </span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="address"
                        :label="$t('MarkPrice.index.操作')"
                        v-if="table4Query.type === 1"
                        min-width="120"
                        align="center"
                      >
                        <template slot-scope="scope">
                          <div class="tb-btn-mn">
                            <a
                              href="javascript:;"
                              class="tb-btn green"
                               v-if="tool.checkButton('edit-assets')"
                              @click="edit(scope.row.id)"
                              >{{ $t("MarkPrice.index.编辑") }}</a>
                            <span>|</span>
                            <a
                              href="javascript:;"
                              class="tb-btn red"
                               v-if="tool.checkButton('del-assets')"
                              @click="priceRemove(scope.row.id)"
                              >{{ $t("MarkPrice.index.删除") }}</a>
                            <span>|</span>
                            <a
                              href="javascript:;"
                              @click="cancelEdit(scope.row.id)"
                               v-if="tool.checkButton('offset-assets')"
                              class="tb-btn red"
                              >{{ $t("MarkPrice.index.抵消") }}</a>
                            <span>|</span>
                            <a
                              href="javascript:;"
                              @click="sellEdit(scope.row.id)"
                               v-if="tool.checkButton('sold-assets')"
                              class="tb-btn green"
                              >{{ $t("MarkPrice.index.售出") }}</a>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                    <div class="btn-flex">
                      <div class="btn-sub" @click="priceAdd" v-show="tool.checkButton('add-assets')">
                      {{ $t("MarkPrice.index.新增") }}
                      </div>
                      <div style="text-align: right; margin: 10px 0">
                    <!-- hide-on-single-page -->
                      <el-pagination
                        :current-page="table4Query.page"
                        :page-size="table4Query.size"
                        @current-change="pageChange4"
                        :total="table4Query.total"
                      >
                      </el-pagination>
                    </div>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="24">
                  <el-col :span="24">
                    <div style="display:flex;justify-content:space-between;align-items:center">
                    <div class="chart-tt bt">
                      <div class="chart-tt-lt"></div>
                      <div class="chart-tt-rt">
                        {{ $t("MarkPrice.index.市场信息") }}
                      </div>
                    </div>
                    <div style="text-align:right">
                    <el-select
                      size="small"
                      style="width: 150px"
                      v-model="productQuery"
                      :placeholder="$t('MarkPrice.index.全部产品')"
                      clearable
                      @change="funSetProductQuery"
                    >
                      <el-option
                        v-for="item in products"
                        :key="item.value"
                        :value="item.value"
                        :label="item.label"
                      >
                      </el-option>
                    </el-select>
                    <el-select
                      size="small"
                      style="width: 150px;margin-left:20px"
                      v-model="timeQuery"
                      :placeholder="$t('MarkPrice.index.全部时间')"
                      clearable
                      @change="funSetTimeQuery"
                    >
                      <el-option
                        v-for="item in times"
                        :key="item.value"
                        :value="item.value"
                        :label="item.label"
                      >
                      </el-option>
                    </el-select>
                    <el-date-picker size="small" unlink-panels align="right" v-show="timeQuery==='5'" style="margin-left:20px;width: 320px" v-model="time" value-format="yyyy-MM-dd" type="daterange" @change="funSetTime"
                      range-separator="~" :start-placeholder="$t('MarkPrice.index.开始日期Msg')" :end-placeholder="$t('MarkPrice.index.结束日期Msg')"
>
                    </el-date-picker>
                  </div>
                  </div>
                  </el-col>
                  <el-col :span="12">
                    <!-- 买盘 -->
                    <div class="tb-tt">{{ $t("MarkPrice.index.买盘") }}</div>
                    <el-table
                      :height="296"
                      :data="tableData1"
                      @cell-mouse-enter="funTimeClear(1)"
                      @cell-mouse-leave="setTimeLoop(1)"
                      stripe
                      border
                      style="width: 100%"
                      :cell-class-name="funTableCell"
                      class="js-scroll"
                    >
                      <el-table-column
                        prop="productInfo.name"
                        :label="$t('MarkPrice.index.产品')"
                        min-width="80"
                        align="center"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="validYear"
                        :label="$t('MarkPrice.index.有效年份')"
                        min-width="80"
                        align="center"
                      ></el-table-column>
                      <el-table-column
                        prop="quantity"
                        :label="$t('MarkPrice.index.数量')"
                        min-width="80"
                        align="center"
                        ><template slot-scope="scope">
                          <span>{{ scope.row.quantity }}
                            {{ scope.row.unit?
                              $t(`MarkPrice.index['${scope.row.unit}']`):'&nbsp;'
                            }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="univalent"
                        :label="$t('MarkPrice.index.单价')"
                        min-width="70"
                        align="center"
                      >
                      <template slot-scope="scope">
                          <span>{{ scope.row.univalent }}
                            {{ scope.row.productInfo.currency }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="createTime"
                        :label="$t('MarkPrice.index.发布时间')"
                        min-width="100"
                        align="center"
                      ></el-table-column>
                      <el-table-column
                        :label="$t('MarkPrice.index.操作')"
                        min-width="80"
                        align="center"
                      >
                        <template slot-scope="scope" v-if="tool.checkButton('inquiry')">
                          <a
                            href="javascript:;"
                            class="tb-btn green"
                            @click="funQuery(scope.row, 1)"
                             v-if="scope.row.isInquiry==='0' && scope.row.id"
                            >{{ $t("MarkPrice.index.询盘") }}</a>
                          <a
                            href="javascript:;"
                            class="tb-btn green"
                            style="color: #acacac"
                            v-if="scope.row.isInquiry!=='0' && scope.row.id"
                            >{{ $t("MarkPrice.index.已询盘") }}</a>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-col>
                  <el-col :span="12">
                    <!-- 卖盘 -->
                    <div class="tb-tt">{{ $t("MarkPrice.index.卖盘") }}</div>
                    <el-table
                      :height="296"
                      :data="tableData2"
                      stripe
                      @cell-mouse-enter="funTimeClear(2)"
                      @cell-mouse-leave="setTimeLoop(2)"
                      border
                      style="width: 100%"
                      :cell-class-name="funTableCell"
                      class="js-scroll"
                    >
                      <el-table-column
                        prop="productInfo.name"
                        :label="$t('MarkPrice.index.产品')"
                        min-width="80"
                        align="center"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="validYear"
                        :label="$t('MarkPrice.index.有效年份')"
                        min-width="80"
                        align="center"
                      ></el-table-column>
                      <el-table-column
                        prop="quantity"
                        :label="$t('MarkPrice.index.数量')"
                        min-width="70"
                        align="center"
                        ><template slot-scope="scope">
                          <span>{{ scope.row.quantity }}
                            {{ scope.row.unit? $t(`MarkPrice.index['${scope.row.unit}']`): '&nbsp;'
                            }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="univalent"
                        :label="$t('MarkPrice.index.单价')"
                        min-width="70"
                        align="center"
                      >
                      <template slot-scope="scope">
                          <span>{{ scope.row.univalent }}
                            {{ scope.row.productInfo.currency }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="createTime"
                        :label="$t('MarkPrice.index.发布时间')"
                        min-width="100"
                        align="center"
                      ></el-table-column>
                      <el-table-column
                        :label="$t('MarkPrice.index.操作')"
                        min-width="80"
                        align="center"
                      >
                        <template slot-scope="scope" v-if="tool.checkButton('inquiry')">
                          <a
                            href="javascript:;"
                            class="tb-btn green"
                            @click="funQuery(scope.row, 2)"
                             v-if="scope.row.isInquiry==='0' && scope.row.id"
                            >{{ $t("MarkPrice.index.询盘") }}</a>
                          <a
                            href="javascript:;"
                            class="tb-btn green"
                            style="color: #acacac"
                            v-if="scope.row.isInquiry!=='0' && scope.row.id"
                            >{{ $t("MarkPrice.index.已询盘") }}</a>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <!-- <div class="chart-tt top">
                      <div class="chart-tt-lt"></div>
                      <div class="chart-tt-rt">
                        {{ $t("MarkPrice.index.我的发布") }}
                      </div>
                    </div> -->
                  <div class="btn-sub" @click="add" v-if="tool.checkButton('release-info')">
                      {{ $t("MarkPrice.index.发布") }}
                    </div>
                  </el-col>
                  <el-col :span="24">
                  <div class="btn-release" @click="inquiryShow">
                      {{ $t("MarkPrice.index.我的询盘") }}
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <sub-dialog
      v-if="$options.components.subDialog"
      ref="subDialog"
    ></sub-dialog>
    <price-dialog
      v-if="$options.components.priceDialog"
      ref="priceDialog"
    ></price-dialog>
    <sell-dialog
      v-if="$options.components.sellDialog"
      ref="sellDialog"
    ></sell-dialog>
    <cancel-dialog
      v-if="$options.components.cancelDialog"
      ref="cancelDialog"
    ></cancel-dialog>
    <release-dialog
      v-if="$options.components.releaseDialog"
      ref="releaseDialog"
    ></release-dialog>
    <inquiry-dialog
      v-if="$options.components.inquiryDialog"
      ref="inquiryDialog"
    ></inquiry-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import api from '@/api'
import tool from '@/tool'
import getConfig from '@/config'
import VueCookie from 'vue-cookie'
import * as echarts from 'echarts'
import moment from 'moment'
import subDialog from './subDialog'
import priceDialog from './priceDialog'
import sellDialog from './sellDialog'
import cancelDialog from './cancelDialog'
import releaseDialog from './releaseDialog'
import inquiryDialog from './inquiryDialog'

function calculateMA (dayCount, data) {
  const result = []
  for (let i = 0, len = data.length; i < len; i++) {
    if (i < dayCount) {
      result.push('-')
      continue
    }
    let sum = 0
    for (let j = 0; j < dayCount; j++) {
      sum += +data[i - j][1]
    }
    result.push((sum / dayCount).toFixed(2))
  }
  return result
}
export default Vue.extend({
  name: 'home',
  directives: {},
  filters: {},
  components: {
    subDialog,
    priceDialog,
    sellDialog,
    cancelDialog,
    releaseDialog,
    inquiryDialog
  },
  props: {},
  data () {
    return {
      selectList: [],
      chart1: null,
      chart2: null,
      chart3: null,
      chart4: null,
      chart5: null,
      chart1Empty: false,
      chart2Empty: false,
      chart3Empty: false,
      chart4Empty: false,
      chart5Empty: false,
      marketList: [], // 市场
      productList: [], // 产品
      regionList: [], // 区域
      marketObj: {
        0: {
          name: '',
          key: '',
          productId: '',
          regionId: '',
          option: []
        },
        1: {
          name: '',
          key: '',
          productId: '',
          regionId: '',
          option: []
        },
        2: {
          name: '',
          key: '',
          productId: '',
          regionId: '',
          option: []
        },
        3: {
          name: '',
          key: '',
          productId: '',
          regionId: '',
          option: []
        },
        4: {
          name: '',
          key: '',
          productId: '',
          regionId: '',
          option: []
        }
      },
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1517 弄'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1519 弄'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄'
      }],
      tableData1: [],
      tableData2: [],
      tableData4: [],
      table4Loading: false,
      table1Query: {
        tradingDirection: '1',
        dateType: '',
        endDate: '',
        startDate: '',
        productId: ''
        // page: 1,
        // size: 5,
        // total: 0,
        // queryParam: {
        //   tradingDirection: '1'
        // }
      },
      table2Query: {
        tradingDirection: '2',
        dateType: '',
        endDate: '',
        startDate: '',
        productId: ''
        // page: 1,
        // size: 5,
        // total: 0,
        // queryParam: {
        //   tradingDirection: '2'
        // }
      },
      table4Query: {
        page: 1,
        size: 5,
        total: 0,
        type: 1, // 2为历史
        queryParam: {

        }
      },
      productQuery: '',
      timeQuery: '',
      products: [], // 全部产品
      times: [{
        value: '1',
        label: this.$t('MarkPrice.index.最近一周')
      }, {
        value: '2',
        label: this.$t('MarkPrice.index.最近一月')
      }, {
        value: '3',
        label: this.$t('MarkPrice.index.最近三月')
      }, {
        value: '4',
        label: this.$t('MarkPrice.index.最近半年')
      }, {
        value: '5',
        label: this.$t('MarkPrice.index.自定义')
      }],
      time: [],
      interval1: null,
      interval2: null
    }
  },
  computed: {

  },
  watch: {},
  beforeCreate () {
  },
  beforeMount () {
  },
  created () {
  },
  async mounted () {
    // this.resizeCanvas()

    // window.addEventListener('resize', _ => this.resizeCanvas(), false)
    await this.getCodeEnum()
    // chart1
    this.setChart1()
    this.setChart2()
    this.setChart3()
    this.setChart4()
    this.init()
    // this.setChart5()
    this.setTimeLoop(1)
    this.setTimeLoop(2)
  },
  activated () {
  },
  deactivated () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
    this.funTimeClear(1)
    this.funTimeClear(2)
  },
  destroyed () {
  },
  methods: {
    init () {
      api.price.queryOptionTypeList({ type: 'marketProductType' }).then(res => {
        this.products = res.data.map(item => ({ ...item, label: item.name, value: item.id }))
      })
      this.pageChange1()
      this.pageChange2()
      // this.pageChange3(1)
      this.pageChange4(1)
    },
    funTimeClear (table) {
      switch (table) {
        case 1:
          if (this.interval1) {
            clearInterval(this.interval1)
          }
          break
        case 2:
          if (this.interval2) {
            clearInterval(this.interval2)
          }
          break
      }
    },
    setTimeLoop (table) {
      const t = document.getElementsByClassName('el-table__body-wrapper')
      switch (table) {
        case 1:
          if (this.interval1) {
            clearInterval(this.interval1)
          }
          // t[0].style.transition = 'all ease .5s'
          this.interval1 = setInterval(async () => {
            //  加入数据
            if (this.tableData1.length > 5 && Math.abs(t[1].scrollHeight - t[1].clientHeight - t[1].scrollTop) < 1) {
              await this.pageChange1()
              // t[1].scrollTo({ top: 0, behavior: 'smooth' })
            } else {
              t[1].scrollTop++
              // t[1].scrollTo({ top: t[1].scrollTop++, behavior: 'smooth' })
            }
          }, 30)
          break
        case 2:
          if (this.interval2) {
            clearInterval(this.interval2)
          }
          // t[0].style.transition = 'all ease .5s'
          this.interval2 = setInterval(async () => {
            if (this.tableData2.length > 5 && Math.abs(t[2].scrollHeight - t[2].clientHeight - t[2].scrollTop) < 1) {
              await this.pageChange2()
              // t[2].scrollTo({ top: 0, behavior: 'smooth' })
            } else {
              t[2].scrollTop++
              // t[2].scrollTo({ top: t[2].scrollTop++, behavior: 'smooth' })
            }
          }, 30)
          break
      }
    },
    funSetProductQuery (val) {
      if (val) {
        this.table1Query.productId = val
        this.table2Query.productId = val
      } else {
        this.table1Query.productId = ''
        this.table2Query.productId = ''
      }
      this.pageChange1()
      this.pageChange2()
    },
    funSetTimeQuery (val) {
      if (val) {
        this.table1Query.dateType = val
        this.table2Query.dateType = val
      } else {
        this.table1Query.dateType = ''
        this.table2Query.dateType = ''
      }
      this.table1Query.startDate = ''
      this.table1Query.endDate = ''
      this.table2Query.startDate = ''
      this.table2Query.endDate = ''
      this.time = []
      this.pageChange1()
      this.pageChange2()
    },
    funSetTime (val) {
      if (val && val.length === 2) {
        this.table1Query.startDate = val[0]
        this.table1Query.endDate = val[1]
        this.table2Query.startDate = val[0]
        this.table2Query.endDate = val[1]
      } else {
        this.table1Query.startDate = ''
        this.table1Query.endDate = ''
        this.table2Query.startDate = ''
        this.table2Query.endDate = ''
      }
      this.pageChange1()
      this.pageChange2()
    },
    async pageChange1 (page) {
      // this.table1Query.page = page
      await this.getTrade(this.table1Query)
      const t = document.getElementsByClassName('el-table__body-wrapper')
      t[1].scrollTop = 0
    },
    async pageChange2 (page) {
      // this.table2Query.page = page
      await this.getTrade(this.table2Query)
      const t = document.getElementsByClassName('el-table__body-wrapper')
      t[2].scrollTop = 0
    },
    pageChange4 (page) {
      this.table4Query.page = page
      this.getHold(this.table4Query)
    },
    setQuery4 (type) {
      this.table4Loading = true
      this.tableData4 = []
      this.table4Query.type = type
      this.pageChange4(1)
    },
    handleGoLogin () {
      this.$router.push('/login')
    },
    handleLogout () {
      tool.clearLocalData()
      this.$store.commit('clearUser', {})
      api.price.getAuthLogout({}).then(res => { })
      this.$router.replace('/login')
    },
    handleGoPath (item) { // TODO 写法太乱 有问题
      if (!item.path) {
        this.$message.error('暂未开放')
        return /// emission
      }
      var wList = []
      if (wList.includes(item.name)) {
        window.open(getConfig().pathZeus + item.path, '_blank')
      } else if (item.path.indexOf('http') > -1 || item.path.indexOf('https') > -1) {
        window.open(item.path, '_blank')
      } else if (!VueCookie.get('client-token')) {
        window.open(getConfig().pathZeus + '/login?redirect=' + encodeURIComponent(item.path + '?sid=' + item.id))
      } else {
        api.menu.authMenu({ id: item.id }).then(res => {
          // alert('23')
          window.open(getConfig().pathZeus + item.path + '?sid=' + item.id, '_blank')
          // if (res.data && res.data.length) {
          //
          // }else{
          //   this.$router.
          // }
          // Vue.local.set('navData', res.data || [], 1000 * 60 * 60 * 24 * 31)
          // if (res.data && res.data[0]) {
          //   const menuOne = res.data[0]
          //   if (menuOne.path.includes('http')) return window.open(menuOne.path, '_blank')
          //   this.$router.push(res.data[0].path)
          // }
        }).catch(_ => {

        })
      }
    },
    toggleLang (lang) {
      if (lang === 'zh') {
        localStorage.setItem('locale', 'zh')
        this.$i18n.locale = localStorage.getItem('locale')
        this.$message({
          message: '切换为中文！',
          type: 'success'
        })
      } else if (lang === 'en') {
        localStorage.setItem('locale', 'en')
        this.$i18n.locale = localStorage.getItem('locale')
        this.$message({
          message: 'Switch to English!',
          type: 'success'
        })
      }
    },
    async setChart1 () {
      var chartDom = document.getElementById('chart1')
      this.chart1 = echarts.init(chartDom)
      var option

      const query = {
        marketId: this.marketObj['0'].key,
        productId: this.marketObj['0'].productId,
        regionId: this.marketObj['0'].regionId,
        chart: '2'
      }
      if (query.productId === '' || query.regionId === '') {
        this.chart1Empty = true
        return false
      }
      // 获取图表数据
      const res = await api.price.queryChatList(query)
      const rawData = res.data.chartData
      const dates = rawData.map(o => o[0])
      const data = rawData.map(o => o[1])
      // prettier-ignore
      const colorList = ['#73DEDE', '#FF7151', '#FFAE26']
      const labelFont = 'bold 12px Sans-serif'

      // prettier-ignore
      // const volumes = res.data.turnoverData
      option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          show: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: dates,
          axisTick: {
            alignWithLabel: true
          },
          axisLine: {
            lineStyle: {
              color: '#999999'
            }
          }
        },
        axisPointer: {
          show: true,
          triggerTooltip: false
        },
        yAxis: [
          {
            type: 'value',
            name: this.$t('MarkPrice.index.RMB'),
            nameGap: 30,
            nameTextStyle: { color: '#999999', fontSize: 14 },
            min: 0,
            axisLabel: {
              color: '#999999',
              formatter: '{value}'
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            splitLine: {
              lineStyle: {
                color: ['#cccccc'],
                type: 'dashed'
              }
            }
          },
          {
            type: 'value',
            name: this.$t('MarkPrice.index.成交量'),
            nameGap: 100,
            min: 0,
            axisLabel: {
              color: '#999999',
              formatter: '{value}'
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            splitLine: {
              show: false
            }
          }
        ],
        series: [
          {
            name: this.$t('MarkPrice.index.RMB'),
            type: 'line',
            data: data,
            symbol: 'circle', // 设定为实心点
            symbolSize: 5, // 设定中心点的大小
            color: 'rgba(254, 111, 80, 1)'
          },
          {
            name: this.$t('MarkPrice.index.成交量'),
            type: 'bar',
            yAxisIndex: 1,
            data: res.data.turnoverData,
            color: 'rgba(0, 130, 62, 1)',
            barWidth: 32// 固定柱子宽度
            // markPoint: {
            //   data: [
            //     { type: 'max', name: '最高价' },
            //     { type: 'min', name: '最低价' }
            //   ]
            // },
            // markLine: {
            //   data: [{ type: 'average', name: '均价' }]
            // }
          }
        ]
      }

      if (data.length) {
        this.chart1Empty = false
        this.chart1 = echarts.init(chartDom)
        option && this.chart1.setOption(option)
      } else {
        this.chart1Empty = true
      }
    },
    async setChart2 () {
      var chartDom = document.getElementById('chart2')
      this.chart2 = echarts.init(chartDom)
      var option

      const query = {
        marketId: this.marketObj['1'].key,
        productId: this.marketObj['1'].productId,
        regionId: this.marketObj['1'].regionId,
        chart: '2'
      }
      if (query.productId === '' || query.regionId === '') {
        this.chart2Empty = true
        return false
      }
      // 获取图表数据
      const res = await api.price.queryChatList(query)
      const rawData = res.data.chartData
      const dates = rawData.map(o => o[0])
      const data = rawData.map(o => o[1])

      // prettier-ignore
      // const volumes = res.data.turnoverData
      option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          show: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: dates,
          axisTick: {
            alignWithLabel: true
          },
          axisLine: {
            lineStyle: {
              color: '#999999'
            }
          }
        },
        axisPointer: {
          show: true,
          triggerTooltip: false
        },
        yAxis: [
          {
            type: 'value',
            name: this.$t('MarkPrice.index.EUR'),
            nameGap: 30,
            nameTextStyle: { color: '#999999', fontSize: 14 },
            min: 0,
            axisLabel: {
              color: '#999999',
              formatter: '{value}'
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            splitLine: {
              lineStyle: {
                color: ['#cccccc'],
                type: 'dashed'
              }
            }
          },
          {
            type: 'value',
            name: this.$t('MarkPrice.index.成交量'),
            nameGap: 100,
            min: 0,
            axisLabel: {
              color: '#999999',
              formatter: '{value}'
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            splitLine: {
              show: false
            }
          }
        ],
        series: [
          {
            name: this.$t('MarkPrice.index.EUR'),
            type: 'line',
            data: data,
            symbol: 'circle', // 设定为实心点
            symbolSize: 5, // 设定中心点的大小
            color: 'rgba(254, 111, 80, 1)'
          },
          {
            name: this.$t('MarkPrice.index.成交量'),
            type: 'bar',
            data: res.data.turnoverData,
            color: 'rgba(0, 130, 62, 1)',
            barWidth: 32, // 固定柱子宽度
            yAxisIndex: 1
          }
        ]
      }
      if (data.length) {
        this.chart2Empty = false
        this.chart2 = echarts.init(chartDom)
        option && this.chart2.setOption(option)
      } else {
        this.chart2Empty = true
      }
    },
    async setChart3 () {
      var chartDom = document.getElementById('chart3')
      var option

      const query = {
        marketId: this.marketObj['2'].key,
        productId: this.marketObj['2'].productId,
        regionId: this.marketObj['2'].regionId,
        chart: '2'
      }
      if (query.productId === '' || query.regionId === '') {
        this.chart3Empty = true
        return false
      }
      // 获取图表数据
      const res = await api.price.queryChatList(query)
      const rawData = res.data.chartData
      const dates = rawData.map(o => o[0])
      const data = rawData.map(o => o[1])

      // prettier-ignore
      // const volumes = res.data.turnoverData
      option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          show: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: dates,
          axisTick: {
            alignWithLabel: true
          },
          axisLine: {
            lineStyle: {
              color: '#999999'
            }
          }
        },
        axisPointer: {
          show: true,
          triggerTooltip: false
        },
        yAxis: [
          {
            type: 'value',
            name: this.$t('MarkPrice.index.RMB'),
            nameGap: 30,
            nameTextStyle: { color: '#999999', fontSize: 14 },
            min: 0,
            axisLabel: {
              color: '#999999',
              formatter: '{value}'
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            splitLine: {
              lineStyle: {
                color: ['#cccccc'],
                type: 'dashed'
              }
            }
          },
          {
            type: 'value',
            name: this.$t('MarkPrice.index.成交量'),
            nameGap: 100,
            min: 0,
            axisLabel: {
              color: '#999999',
              formatter: '{value}'
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            splitLine: {
              show: false
            }
          }
        ],
        series: [
          {
            name: this.$t('MarkPrice.index.RMB'),
            type: 'line',
            data: data,
            symbol: 'circle', // 设定为实心点
            symbolSize: 5, // 设定中心点的大小
            color: 'rgba(56, 112, 225, 1)'
          },
          {
            name: this.$t('MarkPrice.index.成交量'),
            type: 'bar',
            data: res.data.turnoverData,
            color: 'rgba(0, 130, 62, 1)',
            barWidth: 32, // 固定柱子宽度
            yAxisIndex: 1
          }
        ]
      }
      if (data.length) {
        this.chart3Empty = false
        this.chart3 = echarts.init(chartDom)
        option && this.chart3.setOption(option)
      } else {
        this.chart3Empty = true
      }
    },
    async setChart4 () {
      var chartDom = document.getElementById('chart4')
      var option

      const query = {
        marketId: this.marketObj['3'].key,
        productId: this.marketObj['3'].productId,
        regionId: this.marketObj['3'].regionId,
        chart: '2'
      }
      if (query.productId === '') {
        this.chart4Empty = true
        return false
      }
      // 获取图表数据
      const res = await api.price.queryChatList(query)
      const rawData = res.data.chartData
      const dates = rawData.map(o => o[0])
      const data = rawData.map(o => o[1])

      // prettier-ignore
      // const volumes = res.data.turnoverData
      option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          show: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: dates,
          axisTick: {
            alignWithLabel: true
          },
          axisLine: {
            lineStyle: {
              color: '#999999'
            }
          }
        },
        axisPointer: {
          show: true,
          triggerTooltip: false
        },
        yAxis: [
          {
            type: 'value',
            name: 'USD',
            nameGap: 30,
            nameTextStyle: { color: '#999999', fontSize: 14 },
            min: 0,
            axisLabel: {
              color: '#999999',
              formatter: '{value}'
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            splitLine: {
              lineStyle: {
                color: ['#cccccc'],
                type: 'dashed'
              }
            }
          },
          {
            type: 'value',
            name: this.$t('MarkPrice.index.成交量'),
            nameGap: 100,
            min: 0,
            axisLabel: {
              color: '#999999',
              formatter: '{value}'
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            splitLine: {
              show: false
            }
          }
        ],
        series: [
          {
            name: 'USD',
            type: 'line',
            data: data,
            symbol: 'circle', // 设定为实心点
            symbolSize: 5, // 设定中心点的大小
            color: 'rgba(56, 112, 225, 1)'
          },
          {
            name: this.$t('MarkPrice.index.成交量'),
            type: 'bar',
            data: res.data.turnoverData,
            color: 'rgba(0, 130, 62, 1)',
            barWidth: 32, // 固定柱子宽度
            yAxisIndex: 1
          }
        ]
      }
      if (data.length) {
        this.chart4Empty = false
        this.chart4 = echarts.init(chartDom)
        option && this.chart4.setOption(option)
      } else {
        this.chart4Empty = true
      }
    },
    async getCodeEnum () {
      await api.price.queryOptionTypeList({ type: 'marketType' }).then(async res => {
        this.marketList = res.data.map(item => ({ ...item, label: item.name, value: item.id }))
        for (let i = 0; i < this.marketList.length; i++) {
          await api.price.queyOptinTypeByMarkid({
            marketId: this.marketList[i].value,
            types: ['marketProductType']
          }).then(rep => {
            this.marketObj[i] = {
              key: this.marketList[i].value,
              name: this.marketList[i].label,
              productId: rep.data.marketProductType.length ? rep.data.marketProductType[0].id : '',
              productOption: rep.data.marketProductType.map(item => ({ ...item, label: item.name, value: item.id }))
            }
          })
        }
      })
    },
    // 获取市场信息
    async getTrade (query) {
      await api.price.queryMarketInfoPage(query).then(res => {
        this['tableData' + query.tradingDirection] = res.data.map(o => ({ ...o, createTime: moment(o.createTime).format('YYYY-MM-DD'), quantity: o.quantity, unit: o.productInfo.unit }))
        // this['table' + query.queryParam.tradingDirection + 'Query'].total = res.data.total
        if (this['tableData' + query.tradingDirection].length > 5) {
          this['tableData' + query.tradingDirection].push(...new Array(3).fill({
            createTime: '',
            customId: '',
            id: '',
            isCustomer: '',
            isInquiry: '',
            productId: '',
            productInfo: {
              name: '',
              currency: ''
            },
            handlenum: '',
            unit: '',
            quantity: '',
            tradingDirection: '',
            univalent: '',
            updateTime: '',
            validYear: ''
          }))
        }
      })
    },
    // 获取碳资产持仓/历史
    getHold (query) {
      if (query.type === 1) {
        api.price.queryMyAssetsInfoPage(query).then(res => {
          this.tableData4 = res.data.records.map(o => ({ ...o, entryDate: moment(o.entryDate).format('YYYY-MM-DD'), quantity: o.quantity, unit: o.productInfo.unit }))
          this.table4Query.total = res.data.total
          this.table4Loading = false
        })
      } else {
        api.price.queryMyhistoryAssetsInfoPage(query).then(res => {
          this.tableData4 = res.data.records.map(o => ({ ...o, entryDate: moment(o.entryDate).format('YYYY-MM-DD'), createTime: moment(o.createTime).format('YYYY-MM-DD'), status: o.status === 1 ? '抵消' : '售出', handlenum: o.handlenum, unit: o.assetsInfo.productInfo.unit }))
          this.table4Query.total = res.data.total
          this.table4Loading = false
        })
      }
    },
    // 新增
    add () {
      this.$refs.subDialog.add()
    },
    // 我的发布
    releaseShow () {
      this.$refs.releaseDialog.show()
    },
    // 我的询盘
    inquiryShow () {
      this.$refs.inquiryDialog.show()
    },
    // 新增资产
    priceAdd () {
      this.$refs.priceDialog.add()
    },
    // 售出资产
    async sellEdit (id) {
      const res = await api.price.findAssetsInfoById({ id })
      this.$refs.sellDialog.edit({
        ...(res.data || {})
      })
    },
    funTableCell ({ row, column, rowIndex, columnIndex }) {
      let str = ''
      if (row.isCustomer === '1') {
        str = 'cell-self'
      }
      return str
    },
    // 询盘
    async funQuery (row, table) {
      if (row.isCustomer === '1') return false
      await this.$confirm(this.$t('MarkPrice.index.inquiryTooltip'), {
        type: 'warning',
        closeOnClickModal: false,
        closeOnPressEscape: false
      })
      const res = await api.price.inquiry({ id: row.id })
      if (table === 1) {
        this.pageChange1()
      } else {
        this.pageChange2()
      }
      // this.$message.success('已发送询盘信息, 对方收到后与您联系!')
      this.$message.success(res.msg)
    },
    // 抵消资产
    async cancelEdit (id) {
      const res = await api.price.findAssetsInfoById({ id })
      this.$refs.cancelDialog.edit({
        ...(res.data || {})
      })
    },

    // 删除资产
    async priceRemove (id) {
      await this.$confirm(this.$t('MarkPrice.index.deleteTooltip'), {
        type: 'warning',
        closeOnClickModal: false,
        closeOnPressEscape: false
      })
      await api.price.deleteAssetsInfoById({ id: id })

      this.init()
    },
    // 编辑碳资产
    async edit (id) {
      const res = await api.price.findAssetsInfoById({ id })
      this.$refs.priceDialog.edit({
        ...({ ...res.data, entryDate: moment(res.data.entryDate).format('YYYY-MM-DD') } || {})
      })
    }
  }
})
</script>
<style lang="scss">
.cell-self .cell,.cell-self span, .cell-self a{
  color: rgb(172, 172, 172)!important;
  cursor: not-allowed;
}
</style>
<style lang="scss" scoped>
@import "./../../assets/css/global-variable";

.g-box {
  overflow-x: hidden;
  height: 100%;
  background: #ffffff;
}
.home-box {
  width: 100%;
  height: 64px;
  background: #206e55;
  position: relative;

  img {
    width: 100%;
  }
}

#myCanvas-wrap {
  width: 100%;
  // min-width: 1600px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: auto;
  background: #f7f8f7;
}

#myCanvas {
  display: block;
  position: relative;
  width: 1440px;
  // width: 90%;
  margin: 0 auto;
  // height: 900px;
  // position: absolute;
  // background: url("~assets/img/home/bg.jpg") no-repeat 50% 0;
  background-size: 1440px;
  // background-size: 90%;
  // &:before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: -1000px;
  //   width: 1000px;
  //   height: 475px;
  // background: #0aa667;
  // }
  // &:after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   right: -1000px;
  //   width: 1000px;
  //   height: 475px;
  // background: #0aa667;
  // }
}

.lang-item {
  border: solid 1px #ffffff;
  border-radius: 4px;
  padding: 5px;
}

.market-main {
  background-color: #f7f8f7;
  padding: 24px 24px 0;
}

.chart-select {
  width: 150px;
  display: inline-block;

  &:last-child {
    margin-left: 24px;
  }
}

.chart-panel {
  padding: 24px;
  background-color: white;
  border-radius: 4px;
  margin-bottom: 24px;
}

.chart-height {
  height: 353px;
}

.chart-tt {
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;

  &-lt {
    width: 4px;
    height: 22px;
    background: #09a666;
    opacity: 1;
    border-radius: 10px;
  }

  &-rt {
    margin-left: 8px;
    opacity: 0.85;
  }

  &.top {
    margin-top: 24px;
    margin-bottom: 16px;
  }

  &.bt {
    margin-bottom: 16px;
  }
}

.chart {
  height: 100%;
}

.btn-sub {
  width: 88px;
  height: 32px;
  background: #09a666;
  opacity: 1;
  border-radius: 3px;
  color: white;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  margin: 16px 0 24px;
  cursor: pointer;
}
.btn-release{
  width: 88px;
  height: 32px;
  background: #09a666;
  opacity: 1;
  border-radius: 3px;
  color: white;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  margin: 0 auto 0;
  cursor: pointer;
}
.btn-flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tb-btn-mn {
  display: flex;
  justify-content: space-around;
  align-items: center;

  span {
    color: #e9e9e9;
  }
}

.tb-tt {
  width: 84px;
  height: 36px;
  background: #ffffff;
  border-top: 1px solid #dcdcdc;
  border-left: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  opacity: 1;
  border-radius: 0px 2px 0px 0px;
  font-size: 14px;
  color: #09a666;
  text-align: center;
  line-height: 36px;

  &.price {
    color: #575757;
    cursor: pointer;

    &:last-child {
      border-left: none;
    }

    &.active {
      color: #09a666;
      border-top: 2px solid #09a666;
    }
  }
}

.tb-btn {
  font-size: 14px;

  &.green {
    color: #09a666;
  }

  &.red {
    color: #ff4d4f;
  }
}
</style>
