<template>
  <div v-if="dialogFormVisible2">
    <el-dialog :title="titleTable" :visible.sync="dialogFormVisible" :width="'600px'" @close="close"
      :close-on-click-modal="false"
>
      <el-form :model="deviceInfoForm" :rules="rules" ref="form" :label-width="'110px'" label-position="right"
        :class="{ 'my-form-readonly': isModelInfo }" size="large"
>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item :label="$t('MarkPrice.index.资产名称') + '：'">
              <!-- <el-select
                filterable
                v-model="deviceInfoForm.tradingDirection"
                :placeholder="$t('MarkPrice.sell.交易方向Msg')"
              >
                <el-option
                  v-for="item in typeList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>-->
              <span>{{ deviceInfoForm.productInfo.name }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="sellprice" :label="$t('MarkPrice.sell.售出单价') + '：'">
              <el-input v-model="deviceInfoForm.sellprice" :placeholder="$t('global.请输入')">
              <template slot="append">
                  <span>{{deviceInfoForm.productInfo.currency}}</span>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="sellnum" :label="$t('MarkPrice.sell.数量') + '：'">
              <el-input v-model="deviceInfoForm.sellnum" :placeholder="$t('global.请输入')">
                <template slot="append">
                  <span>{{ $t(`MarkPrice.index['${deviceInfoForm.productInfo.unit}']`) }}</span>
                </template>
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item prop="handleDate" :label="$t('MarkPrice.sell.售出日期') + '：'">
              <el-date-picker type="date" value-format="yyyy-MM-dd" style="width: 100%"
                v-model="deviceInfoForm.handleDate" :placeholder="$t('MarkPrice.sell.售出日期Msg')"
></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20"></el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div v-if="isModelInfo">
          <el-button type="primary" :loading="posting" size="small" @click="close()">{{ $t("global.confirm") }}
          </el-button>
        </div>
        <div v-else>
          <el-button @click="close" size="small">{{ $t("global.cancel") }}</el-button>
          <el-button type="primary" :loading="posting" size="small" @click="submitForm('form')">{{
            $t("global.confirm")
          }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api'
import base from '@/templates/add'
export default {
  directives: {},
  filters: {},
  loading: false,
  extends: base,
  props: {},
  data () {
    return {
      titleTable: '',
      dialogFormVisible2: false,
      dialogFormVisible: false,
      posting: false,
      isModelEdit: false,
      isModelAdd: false,
      isModelInfo: false,
      deviceInfoForm: {
        customId: '',
        entryDate: '',
        handleDate: '',
        id: '',
        marketId: '',
        productId: '',
        quantity: '',
        regionId: '',
        productInfo: {
          name: ''
        },
        sellnum: '',
        sellprice: '',
        typeId: '',
        univalent: '',
        validYear: ''
      },
      rules: {
        handleDate: [
          { required: true, message: this.$t('global.请选择'), trigger: ['blur', 'change'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        sellnum: [
          { required: true, message: this.$t('global.请输入'), trigger: ['blur'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg'), trigger: ['blur'] },
          { pattern: this.pattern('IntAndZero'), message: this.$t('global.floatMsg'), trigger: ['blur'] }
        ],
        sellprice: [
          { required: true, message: this.$t('global.请输入'), trigger: ['blur'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg'), trigger: ['blur'] },
          { pattern: this.pattern('floatAndZero'), message: this.$t('global.floatMsg'), trigger: ['blur'] }
        ]
      },
      typeList: []
    }
  },
  created () {
    api.auth.queryOptionTypeList({ type: 'warmGasType' }).then(res => {
      this.typeList = res.data.map(item => ({ ...item, label: item.name, value: item.id }))
    })
  },
  methods: {
    show () {
      this.dialogFormVisible2 = true
      this.$nextTick((_) => {
        this.dialogFormVisible = true
      })
    },
    close () {
      this.isModelEdit = false
      this.isModelAdd = false
      this.isModelInfo = false
      this.dialogFormVisible = false
      this.resetForm()
      this.$nextTick((_) => {
        this.dialogFormVisible2 = false
      })
    },
    add () {
      this.titleTable = this.$t('MarkPrice.sell.addTitle')
      this.isModelAdd = true
      this.show()
    },
    edit (data) {
      this.titleTable = this.$t('MarkPrice.sell.editTitle')
      this.isModelEdit = true
      this.deviceInfoForm = { ...data }
      this.show()
    },
    view (data) {
      this.titleTable = this.$t('MarkPrice.sell.detilsTitle')
      this.isModelInfo = true
      this.deviceInfoForm = { ...data }
      this.show()
    },
    // 重制
    resetForm () {
      this.deviceInfoForm = {
        customId: '',
        entryDate: '',
        handleDate: '',
        id: '',
        marketId: '',
        productId: '',
        quantity: '',
        regionId: '',
        productInfo: {
          name: ''
        },
        sellnum: '',
        sellprice: '',
        typeId: '',
        univalent: '',
        validYear: ''
      }
      this.$refs.form.resetFields()
    },
    // 提交
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.posting = true
          const query = {
            ...this.deviceInfoForm,
            id: this.deviceInfoForm.id,
            updateTime: null
          }
          try {
            await api.price.sellAssetsInfoById(query)
            this.posting = false
            this.$message.success(this.$t('global.submitOk'))
            this.close()
            this.$parent.pageChange4(this.$parent.table4Query.page)
          } catch (error) {
            this.posting = false
          }
        }
      })
    }
  }
}
</script>
