<template>
  <div v-if="dialogFormVisible2">
    <el-dialog :title="titleTable" :visible.sync="dialogFormVisible" :width="'600px'" @close="close"
      :close-on-click-modal="false"
>
      <el-form :model="deviceInfoForm" :rules="rules" ref="form" :label-width="'110px'" label-position="right"
        :class="{ 'my-form-readonly': isModelInfo }" size="large"
>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="tradingDirection" :label="$t('MarkPrice.form.交易方向') + '：'">
              <el-select filterable v-model="deviceInfoForm.tradingDirection"
                :placeholder="$t('MarkPrice.form.交易方向Msg')" @change="funGetProduct"
>
                <el-option value="1" label="求购"></el-option>
                <el-option value="2" label="出售"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="productId" :label="$t('MarkPrice.form.产品') + '：'">
              <el-select filterable v-model="deviceInfoForm.productId" :placeholder="$t('MarkPrice.form.产品Msg')"
                @change="funProductChange"
>
                <el-option v-for="item in productList" :key="item.id" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="validYear" :label="$t('MarkPrice.form.有效年份') + '：'">
              <el-date-picker :disabled="deviceInfoForm.tradingDirection === '2'" value-format="yyyy" type="year"
                style="width: 100%" v-model="deviceInfoForm.validYear" :placeholder="$t('MarkPrice.form.有效年份Msg')"
>
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="quantity" :label="$t('MarkPrice.form.数量') + '：'">
              <el-input v-model="deviceInfoForm.quantity" :placeholder="$t('MarkPrice.form.数量Msg')">
                <template slot="append" v-if="append">
                  <span>{{ append }}</span>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="univalent" :label="$t('MarkPrice.form.单价') + '：'">
              <el-input v-model="deviceInfoForm.univalent" :placeholder="$t('MarkPrice.form.单价Msg')">
                 <template slot="append" v-if="appendUnit">
                  <span>{{ appendUnit }}</span>
                </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div v-if="isModelInfo">
          <el-button type="primary" :loading="posting" size="small" @click="close()">{{ $t("global.confirm") }}
          </el-button>
        </div>
        <div v-else>
          <el-button @click="close" size="small">{{ $t("global.cancel") }}</el-button>
          <el-button type="primary" :loading="posting" size="small" @click="submitForm('form')">{{
              $t("global.confirm")
          }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api'
import base from '@/templates/add'
export default {
  directives: {},
  filters: {},
  loading: false,
  extends: base,
  props: {},
  data () {
    return {
      titleTable: '',
      dialogFormVisible2: false,
      dialogFormVisible: false,
      posting: false,
      isModelEdit: false,
      isModelAdd: false,
      isModelInfo: false,
      deviceInfoForm: {
        customId: '',
        id: '',
        productId: '',
        quantity: '',
        regionId: '',
        tradingDirection: '1',
        typeId: '',
        assetsId: '',
        univalent: '',
        validYear: ''
      },
      rules: {
        tradingDirection: [
          { required: true, message: this.$t('global.请选择'), trigger: ['blur', 'change'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        productId: [
          { required: true, message: this.$t('global.请选择'), trigger: ['blur', 'change'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        validYear: [
          { required: true, message: this.$t('global.请选择'), trigger: ['blur', 'change'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        quantity: [
          { required: true, message: this.$t('global.请输入'), trigger: ['blur'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg'), trigger: ['blur'] },
          { pattern: this.pattern('IntAndZero'), message: this.$t('global.floatMsg'), trigger: ['blur'] }
        ],
        univalent: [
          { required: true, message: this.$t('global.请输入'), trigger: ['blur'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg'), trigger: ['blur'] },
          { pattern: this.pattern('floatAndZero'), message: this.$t('global.floatMsg'), trigger: ['blur'] }
        ],
      },
      productList: [],
      regionList: [],
      projectTypeList: []
    }
  },
  computed: {
    append () {
      let str = ''
      if (this.deviceInfoForm.productId) {
        const obj = this.productList.find(o => o.value === this.deviceInfoForm.productId)
        if (obj.productInfo) {
          str = this.$t(`MarkPrice.index['${obj.productInfo.unit}']`)
        } else {
          str = this.$t(`MarkPrice.index['${obj.unit}']`)
        }
      }
      return str
    },
    appendUnit() {
    let str = ''
      if (this.deviceInfoForm.productId) {
        const obj = this.productList.find(o => o.value === this.deviceInfoForm.productId)
        if (obj.productInfo) {
          str = obj.productInfo.currency
        } else {
          str = obj.currency
        }
      }
      return str
    }
  },
  created () {
    // api.auth.queryOptionTypeList({ type: 'marketType' }).then(res => {
    //   this.marketList = res.data.map(item => ({ ...item, label: item.name, value: item.id }))
    // })
    this.funGetProduct('1')
  },
  methods: {
    // 获取资产
    funGetProduct (val) {
      if (val === '1') {
        api.price.queryOptionTypeList({ type: 'marketProductType' }).then(res => {
          this.productList = res.data.map(item => ({ ...item, label: item.name, value: item.id }))
          this.deviceInfoForm.productId = res.data.length ? res.data[0].id : ''
          this.funProductChange(this.deviceInfoForm.productId)
        })
      } else {
        api.price.queryMyAssetsInfoList({}).then(res => {
          this.productList = res.data.map(item => ({ ...item, label: item.productInfo.name, value: item.id }))
          this.deviceInfoForm.productId = res.data.length ? res.data[0].id : ''
          this.funProductChange(this.deviceInfoForm.productId)
        })
      }
    },
    funProductChange (val) {
      if (val) {
        if (this.deviceInfoForm.tradingDirection === '1') {
          this.deviceInfoForm.assetsId = ''
          // if (this.deviceInfoForm.typeId) this.deviceInfoForm.typeId = ''
          // if (this.deviceInfoForm.regionId) this.deviceInfoForm.regionId = ''
          // if (this.deviceInfoForm.validYear) this.deviceInfoForm.validYear = ''
        } else {
          const obj = this.productList.find(o => o.id === val)
          this.deviceInfoForm.assetsId = obj.productId
          this.deviceInfoForm.validYear = obj.validYear
        }
      }
    },
    show () {
      this.dialogFormVisible2 = true
      this.$nextTick((_) => {
        this.dialogFormVisible = true
      })
    },
    close () {
      this.isModelEdit = false
      this.isModelAdd = false
      this.isModelInfo = false
      this.dialogFormVisible = false
      this.resetForm()
      this.$nextTick((_) => {
        this.dialogFormVisible2 = false
      })
    },
    add () {
      this.titleTable = this.$t('MarkPrice.form.addTitle')
      this.isModelAdd = true
      this.show()
    },
    edit (data) {
      this.titleTable = this.$t('MarkPrice.form.editTitle')
      this.isModelEdit = true
      this.deviceInfoForm = { ...data }
      this.show()
    },
    view (data) {
      this.titleTable = this.$t('MarkPrice.form.detilsTitle')
      this.isModelInfo = true
      this.deviceInfoForm = { ...data }
      this.show()
    },
    // 重制
    resetForm () {
      this.deviceInfoForm = {
        customId: '',
        id: '',
        productId: '',
        quantity: '',
        regionId: '',
        tradingDirection: '1',
        typeId: '',
        assetsId: '',
        univalent: '',
        validYear: ''
      }
      this.funGetProduct(this.deviceInfoForm.tradingDirection)
      this.$refs.form.resetFields()
    },
    // 提交
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.posting = true
          const query = {
            ...this.deviceInfoForm,
            id: this.deviceInfoForm.id,
            productId: this.deviceInfoForm.tradingDirection === '1' ? this.deviceInfoForm.productId : this.deviceInfoForm.assetsId,
            assetsId: this.deviceInfoForm.tradingDirection === '1' ? '' : this.deviceInfoForm.productId,
            updateTime: null
          }
          try {
            await api.price.saveMarketInfo(query)
            this.posting = false
            this.$message.success(this.$t('global.submitOk'))
            this.close()
            this.$parent.pageChange1()
            this.$parent.pageChange2()
          } catch (error) {
            this.posting = false
          }
        }
      })
    }
  }
}
</script>
