<template>
  <div v-if="dialogFormVisible2">
    <el-dialog :title="titleTable" :visible.sync="dialogFormVisible" :width="'1072px'" @close="close"
      :close-on-click-modal="false"
>
      <el-row>
        <el-col :span="24">
          <el-table :height="298" :data="tableData3" stripe border style="width: 100%">
            <el-table-column prop="tradingDirection" :label="$t('MarkPrice.index.交易方向')" min-width="80" align="center">
              <template
                      slot-scope="scope"
>
                  {{ $t(`MarkPrice.index.${scope.row.tradingDirection}`) }}

              </template>
            </el-table-column>
            <el-table-column prop="productInfo.name" :label="$t('MarkPrice.index.产品')" min-width="80" align="center">
            </el-table-column>
            <el-table-column prop="validYear" :label="$t('MarkPrice.index.有效年份')" min-width="80" align="center">
            </el-table-column>
            <el-table-column prop="quantity" :label="$t('MarkPrice.index.数量')" min-width="80" align="center">
              <template
                slot-scope="scope"
>
                <span>{{ scope.row.quantity }}
                  {{
                      $t(`MarkPrice.index['${scope.row.unit}']`)
                  }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="univalent" :label="$t('MarkPrice.index.单价')" min-width="80" align="center">
                      <template slot-scope="scope">
                          <span>{{ scope.row.univalent }}
                            {{ scope.row.productInfo.currency }}</span>
                        </template>
            </el-table-column>
            <el-table-column prop="createTime" :label="$t('MarkPrice.index.发布时间')" min-width="80" align="center">
            </el-table-column>
            <el-table-column :label="$t('MarkPrice.index.操作')" min-width="80" align="center">
              <template slot-scope="scope">
                <a href="javascript:;" class="tb-btn red" @click="remove(scope.row.id)"
                  v-if="tool.checkButton('del-info')"
>{{ $t("MarkPrice.index.删除") }}</a>
              </template>
            </el-table-column>
          </el-table>
          <div style="text-align: right; margin: 10px auto">
          <!-- hide-on-single-page -->
            <el-pagination :current-page="table3Query.page" :page-size="table3Query.size"
              @current-change="pageChange3" :total="table3Query.total"
>
            </el-pagination>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api'
import base from '@/templates/add'
import moment from 'moment'
export default {
  directives: {},
  filters: {},
  loading: false,
  extends: base,
  props: {},
  data () {
    return {
      titleTable: this.$t('MarkPrice.index.我的发布'),
      dialogFormVisible2: false,
      dialogFormVisible: false,
      posting: false,
      isModelEdit: false,
      isModelAdd: false,
      isModelInfo: false,
      deviceInfoForm: {
        customId: '',
        entryDate: '',
        handleDate: '',
        id: '',
        marketId: '',
        productId: '',
        quantity: '',
        regionId: '',
        productInfo: {
          name: ''
        },
        sellnum: '',
        sellprice: '',
        typeId: '',
        univalent: '',
        validYear: ''
      },
      rules: {
        handleDate: [
          { required: true, message: this.$t('global.请选择'), trigger: ['blur', 'change'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg'), trigger: ['blur', 'change'] }
        ]
      },
      typeList: [],
      tableData3: [],
      table3Query: {
        page: 1,
        size: 5,
        total: 0,
        queryParam: {
          tradingDirection: ''
        }
      }
    }
  },
  created () {
  },
  methods: {
    pageChange3 (page) {
      this.table3Query.page = page
      this.getSub(this.table3Query)
    },
    // 获取我的发布
    getSub (query) {
      api.price.queryMyMarketInfoPage(query).then(res => {
        this.tableData3 = res.data.records.map(o => ({ ...o, createTime: moment(o.createTime).format('YYYY-MM-DD'), tradingDirection: o.tradingDirection === '1' ? '求购' : '出售', quantity: o.quantity, unit: o.productInfo.unit }))
        this.table3Query.total = res.data.total
      })
    },
    // 删除我的发布
    async remove (id) {
      await this.$confirm(this.$t('global.确定要删除吗'), {
        type: 'warning',
        closeOnClickModal: false,
        closeOnPressEscape: false
      })
      await api.price.deleteMarketInfoById({ id: id })

      if (this.tableData3.length === 1) {
        this.pageChange3(1)
      } else {
        this.pageChange3(this.table3Query.page)
      }
    },
    show () {
      this.dialogFormVisible2 = true
      this.pageChange3(this.table3Query.page)
      this.$nextTick((_) => {
        this.dialogFormVisible = true
      })
    },
    close () {
      this.isModelEdit = false
      this.isModelAdd = false
      this.isModelInfo = false
      this.dialogFormVisible = false
      // this.resetForm()
      this.$nextTick((_) => {
        this.dialogFormVisible2 = false
      })
    },
    add () {
      this.titleTable = this.$t('MarkPrice.cancel.addTitle')
      this.isModelAdd = true
      this.show()
    },
    edit (data) {
      this.titleTable = this.$t('MarkPrice.cancel.editTitle')
      this.isModelEdit = true
      this.deviceInfoForm = { ...data }
      this.show()
    },
    view (data) {
      this.titleTable = this.$t('MarkPrice.index.我的发布')
      this.isModelInfo = true
      this.deviceInfoForm = { ...data }
      this.show()
    },
    // 重制
    resetForm () {
      this.deviceInfoForm = {}
      this.$refs.form.resetFields()
    },
    // 提交
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.posting = true
          const query = {
            ...this.deviceInfoForm,
            id: this.deviceInfoForm.id,
            updateTime: null
          }
          try {
            await api.price.logoffAssetsInfoById(query)
            this.posting = false
            this.$message.success(this.$t('global.submitOk'))
            this.close()
            this.$parent.pageChange4(this.$parent.table4Query.page)
          } catch (error) {
            this.posting = false
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.red{
  color: #ff4d4f;
}
.tb-btn{
  font-size: 14px;
}
</style>
